<template>
  <section
    id="inofficeappointment"
  >
  <v-container :class="$vuetify.breakpoint.mdAndDown ? 'mt-11 pt-11' : 'px-8 mt-11 pt-11'">
    <!-- <heading>Contact Us</heading> -->
      <v-row>
        <v-col cols="12" align="center">
          <h1 :class="$vuetify.breakpoint.mdAndDown ? 'mb-2 text-uppercase' : 'mb-2 big-text text-uppercase'" :style="$vuetify.breakpoint.mdAndDown ? 'font-size: 2em;' : ''">In Office &amp; <br>Teletherapy Appointments</h1>
        </v-col>
        <v-col
          xs12
          md6
        >
         <h2 class="mb-4">Instructions</h2>
          When scheduling an <strong>In Office appointment</strong>, please fill out the below Intake PDF using Adobe Acrobat, or Chromes built-in PDF Viewer. Once filled out, you can save the PDF, and print it. 
          <br>
          <br>
          The Intake PDF must be printed, filled out, and brought with you to the appointment.<br><br>
          <a href="/static/INTAKE-Doug-Feil- FC&C-Fillable.pdf">Intake PDF - Required for In Office Appointment</a>  
          <hr class="my-6">
          <h2 class="mb-4">Teletherapy</h2>
           When scheduling a <strong>Teletherapy Session</strong>, please send an email to <a href="mailto:dfeil@feilcounselingandconsulting.com">dfeil@feilcounselingandconsulting.com</a> requesting a Teletherapy Session, and either Joyce or Doug will send you the online forms required to setup the appointment. 
           <br>
           <br>
          You can also call us at 720-689-4631‬ setup the appointment.
          <br>
          <br>
          Teletherapy is done using either Google Meet, or Phone Call depending on your preference.
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-container
            fluid
            pa-0
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
                class="pa-0"
              >
                <v-card-title>Mailing &amp; Address</v-card-title>
                <v-card-text>
                   <div>6112 S Devinney Way</div>
                  <div>Littleton, CO 80127</div>
                  <div>United States</div>
                </v-card-text>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="pa-0"
              >
                <v-card-title>Contact Info</v-card-title>
                <v-card-text>
                  <div>720-689-4631‬</div>
                  <div>
                    <a href="mailto:dfeil@feilcounselingandconsulting.com">dfeil@feilcounselingandconsulting.com</a>
                  </div>
                  <div>
                    <!-- <a href="#">@alphaconstruction</a> -->
                  </div>
                </v-card-text>
              </v-col>

              <v-col cols="12">
                <iframe
                  src="https://maps.google.com/maps?q=6112%20S%20Devinney%20Way,%20Littleton,%20CO%2080127&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  width="100%"
                  height="400"
                  frameborder="0"
                  style="border:0"
                  allowfullscreen
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  export default {
    metaInfo: {
      title: 'In Office and Teletherapy Options',
      meta: [
        { name: 'description', content: 'We offer both In office and Teletherapy solutions to help you wherever you are.' }
      ]
    },
    data: () => ({
      loaded_title: "",
      loaded_subtitle: "",
      privateinfo: false,
      titles: {
        contact: {
          title: 'Contact Feil Counseling',
          subtitle: 'Aenean viverra rhoncus pede. Morbi mollis tellus ac sapien.'
        }
      }
    }),
    components: {
      Heading: () => import('@/components/Heading')
    }
  }
</script>
